<template>
    <div>
        <Header />     

            <div class="container-fluid">
                <div class="wrap-form-content simple_search search_page">
                    <form @submit.prevent="handleSubmit">    
                    <div class="row"> 
                                            
                        <div class="col-lg-6 col-md-12 col-12">
                            <div class="box-content">                                 
                                    <div class="form-input">
                                        <p class="title-top-input">{{datas.name.title}}</p> 
                                        <Multiselect
                                            v-model="datas.name.data"
                                            mode="tags"
                                            :search="true"
                                            :searchable="true"
                                            :placeholder="datas.name.placeholder"
                                            @open="filteredName"
                                            :options="datas.name.items"
                                            track-by="value"
                                            class="custom-tags-input"
                                            :limit="limit"
                                            :loading="loadingName"
                                            :disabled="loadingName"
                                            :showOptions="datas.name.showOptions"
                                            @search-change="optionsDisplay"
                                            @close="onCloseDrugname"
                                            :strict="false"
                                            :closeOnSelect="false"
                                            @select="onCloseDrugname"
                                        />
                                    </div>
                                    <div class="form-input">
                                        <p class="title-top-input">{{datas.therapeutic.title}}</p>
                                        <Multiselect
                                            v-model="datas.therapeutic.data"
                                            mode="tags"
                                            :search="true"
                                            :searchable="true"
                                            :placeholder="datas.therapeutic.placeholder"
                                            @open="filteredTherapeutic"
                                            :options="datas.therapeutic.items"
                                            track-by="value"
                                            class="custom-tags-input"
                                            :caret="false"
                                            :limit="limit"
                                            ref="multiselectTherapeutic"
                                            :loading="loadingTherapeutic"
                                            :disabled="loadingTherapeutic"
                                            :strict="false"
                                            :closeOnSelect="false"
                                        />
                                         <span class="input-search-plus fr" v-if="!loadingTherapeutic" data-bs-toggle="modal" data-bs-target="#modal-therapeutic"></span> 
                                    </div>                                 
                            
                                <div class="box-border-dashed">
                                    <div class="form-checkbox">
                                        <div class="row">
                                            <div class="col-md-6 col-6 pr-1">
                                                <div v-for="(item, key) in datas.AAP.items" :key="key"  class="box-check relative">
                                                    <label>
                                                        <input type="checkbox" :value="item.value" v-model="datas.AAP.data" :disabled="disabledDrug" name="AAP">
                                                        <span class="check-recherche"></span>
                                                        <div class="txt-check bold"> {{item.display}} </div>
                                                    </label>
                                                    <div class="area-overlay" v-show="disabledDrug"></div>
                                                </div> 
                                            </div>
                                            <div class="col-md-6 col-6 pr-1">
                                                <div v-for="(item, key) in datas.mk_orphelin.items" :key="key"  class="box-check relative">
                                                    <label>
                                                        <input type="checkbox" :value="item.value" v-model="datas.mk_orphelin.data" :disabled="disabledDrug" name="mk_orphelin">
                                                        <span class="check-recherche"></span>
                                                        <div class="txt-check bold"> {{item.display}} </div>
                                                    </label>
                                                    <div class="area-overlay" v-show="disabledDrug"></div>
                                                </div> 
                                            </div>
                                        </div>
                                        <div class="row mt-1">
                                            <div class="col-md-6 col-6 pr-1">
                                                <div v-for="(item, key) in datas.disease.items" :key="key"  class="box-check relative">
                                                    <label>
                                                        <input type="checkbox" :value="item.value" v-model="datas.disease.data" :disabled="disabledDrug" name="disease">
                                                        <span class="check-recherche"></span>
                                                        <div class="txt-check bold"> {{item.display}} </div>
                                                    </label>
                                                    <div class="area-overlay" v-show="disabledDrug"></div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-border-dashed">
                                    <div class="form-rows">
                                        <div class="row">
                                            <div class="col-md-6 col-6 pr-1">
                                                <div class="form-input">
                                                    <p class="title-top-input">{{datas.date_start.title}}</p>
                                                    <datepicker 
                                                        v-model="datas.date_start.data" 
                                                        :locale="language"
                                                        inputFormat="MM/yyyy" 
                                                        minimumView="month"
                                                        class="form-control form-cs input-border-dark bg-white" 
                                                        :placeholder="$t('MM/YYYY')"                                                   
                                                        :clearable="true" 
                                                        >  
                                                        <template v-slot:clear>
                                                            <div class="date_clearable" @click="datas.date_start.data = null"><em>x</em></div>
                                                        </template>
                                                    </datepicker>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-6 pr-1">
                                                <div class="form-input">
                                                    <p class="title-top-input font14">{{datas.date_end.title}}</p>
                                                    <datepicker 
                                                        v-model="datas.date_end.data" 
                                                        :locale="language"
                                                        inputFormat="MM/yyyy" 
                                                        minimumView="month"
                                                        class="form-control form-cs input-border-dark bg-white" 
                                                        :placeholder="$t('MM/YYYY')" 
                                                        :clearable="true"
                                                        >  
                                                        <template v-slot:clear>
                                                            <div class="date_clearable" @click="datas.date_end.data = null"><em>x</em></div>
                                                        </template>
                                                    </datepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="form-checkbox">
                                        <div class="row">
                                            <div class="col-md-6 col-6 pr-1">
                                                <div v-for="(item, key) in datas.en_cours.items" :key="key"  class="box-check relative">
                                                    <label>
                                                        <input type="checkbox" :value="item.value" v-model="datas.en_cours.data" :disabled="disabledDrug" name="en_cours">
                                                        <span class="check-recherche"></span>
                                                        <div class="txt-check bold"> {{item.display}} </div>
                                                    </label>
                                                    <div class="area-overlay" v-show="disabledDrug"></div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="bg-blue relative">
                                    <div class="box-title-head">{{datas.type_demande.title}}</div>
                                    <div class="form-checkbox">
                                        <div class="row">
                                            <div v-for="(item, key) in datas.type_demande.items" :key="key" class="col-md-6 col-xs-12 pr-1">
                                                <div class="box-check box-check-p0">
                                                    <label>
                                                        <input type="checkbox" :value="item.value" v-model="datas.type_demande.data" name="type_demande" :disabled="disabledDrug">
                                                        <span class="check-recherche"></span>
                                                        <div class="txt-check bold"> {{item.display}} </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="area-overlay" v-show="disabledDrug"></div>
                                </div>
                                <div class="bg-blue relative">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="box-title-head">{{datas.smr.title}}</div>
                                            <Multiselect
                                                v-model="datas.smr.data"
                                                mode="tags"
                                                placeholder="Choisir SMR"
                                                :options="datas.smr.items"
                                                :closeOnSelect="false"
                                            />

                                            <!-- <div v-for="(item, key) in datas.smr.items" :key="key"  class="box-check box-check-p0"> 
                                                <label>
                                                    <input type="checkbox" :value="item.value" v-model="datas.smr.data" name="smr" :disabled="disabledDrug">
                                                    <span class="check-recherche"></span>
                                                    <div class="txt-check bold">
                                                            {{item.display}}
                                                    </div>
                                                </label>
                                            </div> -->
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="box-title-head">{{datas.asmr_obtenue.title}}</div>
                                             
                                                <Multiselect
                                                    v-model="datas.asmr_obtenue.data"
                                                    mode="tags"
                                                    placeholder="Choisir ASMR"
                                                    :options="datas.asmr_obtenue.items"
                                                    :closeOnSelect="false"
                                                />
                                                
                                                <!-- <div v-for="(item, key) in datas.asmr_obtenue.items" :key="key" class="col-md-6 col-6">
                                                    <div class="box-check box-check-p0">
                                                        <label>
                                                            <input type="checkbox" :value="item.value" v-model="datas.asmr_obtenue.data" name="asmr_obtenue" :disabled="disabledDrug">
                                                            <span class="check-recherche"></span>
                                                            <div class="txt-check bold">
                                                            {{item.display}}
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>  -->
                                             
                                        </div>
                                    </div>
                                    <div class="area-overlay" v-show="disabledDrug"></div>
                                </div> 

                                <div class="bg-blue relative">
                                    <div class="box-title-head">{{datas.ceesp.title}}</div>
                                    <div class="row">
                                        <div v-for="(item, key) in datas.ceesp.items" :key="key"  class="col-md-6 col-6">
                                            <div class="box-check box-check-p0">
                                                <label>
                                                    <input type="checkbox" :value="item.value" v-model="datas.ceesp.data" :disabled="disabledDrug">
                                                    <span class="check-recherche"></span>
                                                    <div class="txt-check bold">
                                                        {{item.display}}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>                                                
                                    </div>
                                    <div class="area-overlay" v-show="disabledDrug"></div>
                                </div>
                            </div>
                         
                                    
                                    
                                
                        </div>

                        <div class="col-lg-6 col-md-12 col-12">
                            
                            <div class="box-content">
                                
                                <div class="box-border-dashed">
                                <div class="bg-light-blue relative" style="margin-bottom:0">
                                    <div class="box-title-head text-18">{{$t('simple.PRICE')}}</div> 
                                    <div class="box-title-head">{{datas.name_prix.title}}</div>   
                                    <div class="form-input">
                                        <Multiselect
                                            v-model="datas.name_prix.data"
                                            mode="tags"
                                            :search="true"
                                            :searchable="true"
                                            :placeholder="datas.name_prix.placeholder"
                                            @open="filteredBpName"
                                            :options="datas.name_prix.items"
                                            track-by="value"
                                            class="custom-tags-input"
                                            :limit="limit"
                                            :loading="loadingName"
                                            :disabled="loadingName"
                                            :showOptions="datas.name.showOptions"
                                            @search-change="optionsDisplay"
                                            @close="onCloseDrugname"
                                            :strict="false"
                                            :closeOnSelect="false"
                                            @select="onCloseDrugname"
                                        />
                                    </div>

                                    <div class="box-title-head">{{datas.prix.title}}</div>   
                                    <div class="form-input">                                
                                    <Multiselect
                                        v-model="datas.prix.data"
                                        mode="tags"
                                        placeholder="Choisir liste"
                                        :options="datas.prix.items"
                                        :closeOnSelect="false"
                                    /> 
                                   </div>
                                    
                                    <div class="form-input">
                                        <input type="text" :placeholder="datas.cip.placeholder" class="form-control form-cs" @focusout="cipValue" v-model="datas.cip.data" :disabled="disabledPrix">
                                    </div>
                                
                                    <div class="form-input">
                                        <input type="text" :placeholder="datas.cis.placeholder" class="form-control form-cs" v-model="datas.cis.data" :disabled="disabledPrix">
                                    </div>

                                    <div class="form-input">
                                        <input type="text" :placeholder="datas.ucd.placeholder" class="form-control form-cs" v-model="datas.ucd.data" :disabled="disabledPrix">
                                    </div>

                                    <div class="area-overlay" v-show="disabledPrix"></div>
                                </div>
                                </div>

                                <div class="box-border-gray relative" style="margin-bottom:0" v-if="user && user.access_clinical">
                                    <a class="text-title a-clinical"  @click="toggleBoxEssais = !toggleBoxEssais" role="button">
                                        {{$t('Clinical trials')}} <span class="bt-icon-down"><em :class="'fa fa-chevron-' + (toggleBoxEssais ? 'up' : 'down') "></em> </span>
                                    </a>
                                    
                                    <div :class="'collapse' + (toggleBoxEssais ? ' show' : '') " style="padding-top: 10px;">                                        
                                        <div class="form-input">
                                            <p class="title-top-input">{{datas.phase.title}}</p>  
                                            <Multiselect
                                                v-model="datas.phase.data"
                                                mode="tags"
                                                :placeholder="datas.phase.placeholder"
                                                :options="datas.phase.items"
                                                :closeOnSelect="false"
                                            />
                                        </div>
                                        <div class="form-input">
                                            <p class="title-top-input">{{datas.primary_endpoint.title}}</p>  
                                            <Multiselect
                                                v-model="datas.primary_endpoint.data"
                                                mode="tags"
                                                :search="true"
                                                :searchable="true"
                                                :placeholder="datas.primary_endpoint.placeholder"
                                                @open="filteredMeasureCategoryPrimary"
                                                :options="datas.primary_endpoint.items"
                                                track-by="value"
                                                class="custom-tags-input"
                                                :strict="false"
                                                :closeOnSelect="false"
                                            />
                                        </div>
                                        <div class="form-input">
                                            <p class="title-top-input">{{datas.secondary_endpoint.title}}</p>  
                                            <Multiselect
                                                v-model="datas.secondary_endpoint.data"
                                                mode="tags"
                                                :search="true"
                                                :searchable="true"
                                                :placeholder="datas.secondary_endpoint.placeholder"
                                                @open="filteredMeasureCategorySecondary"
                                                :options="datas.secondary_endpoint.items"
                                                track-by="value"
                                                class="custom-tags-input"
                                                :strict="false"
                                                :closeOnSelect="false"
                                            />
                                        </div> 
                                    </div>
                                    <div class="area-overlay" v-show="disabledEssais"></div>
                                </div>
                            </div>

                            

                        </div>
                        <ModalSelection :title="datas.therapeutic.title" :list="autocomplete_therapeutic" name="therapeutic" @onModal="onModal"/>
                        <div class="box-btn-search-simple">                        
                            <div class="btn-search-simple" >
                                <router-link to="/advance">
                                <button class="btn btn-border-blue bt-14">{{$t('simple.MAKE AN ADVANCED SEARCH')}}</button>
                                </router-link>
                            </div>
                            <div class="btn-search-simple">                            
                                <button type="submit" class="btn btn-border-blue btn-blue bt-14 mb-3" >{{$t('Launch the search')}}</button>
                            </div>
                        </div>
                    </div>
                    
                    </form>
                </div>                
            </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Layout/Header.vue' 
import Footer from '@/components/Layout/Footer.vue';
import ModalSelection from '@/components/elements/modal_selection.vue'
import Datepicker from 'vue3-datepicker'
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css'
import '@/assets/css/multiselect-tagsinput.css'
import moment from 'moment'
import {isAdmin, base64url_encode} from "@/utils";
import { enGB, fr, de } from 'date-fns/locale'
/**
 * Simple search form (FR only)
 */
export default {
  name: 'simple',
  components: {
      Header, 
      Footer,
      ModalSelection,
      Datepicker,
      Multiselect,
  },
  data:  function(){
        return {        
            languages : {enGB, fr, de },  
            datas : {
                name : {
                    title : this.$t('simple.name'), 
                    placeholder : "ex: Opdivo",
                    data : [],
                    items : [],
                    main : 'public',
                    input : "",
                    showOptions:false
                },
                therapeutic : {
                    title : this.$t('simple.therapeutic'),                   
                    placeholder : "ex: Melanoma",
                    data : [],
                    items: [],
                    main : 'public',
                    input : "",
                },
                AAP : {
                    title : "", 
                    items : [
                        {value : "1", display : "AAP"}, 
                    ],                
                    data : [],
                    main : 'Acces_precoce'
                },
                mk_orphelin : {
                    title : "",  
                    items : [
                        {value : "1", display : this.$t('simple.Orphan drug') }, 
                    ],                
                    data : [],
                    main : 'public'
                },
                disease: {
                    title: '',
                    items : [
                        {value : "1", display : this.$t('Rare disease') }, 
                    ],                
                    data : [],
                    main : 'public'
                },
                date_start : {
                    title : this.$t('date_start'), 
                    data : null,
                    main : 'public'
                },
                date_end : {
                    title : this.$t('date_end'), 
                    data : null,
                    main : 'public'
                },
                en_cours : {
                    title : "", 
                    items : [
                        {value : "1", display : this.$t('Ongoing assessment')}, 
                    ],                
                    data : [],
                    main : 'public'
                },
                type_demande : {
                    title: this.$t('simple.type_demande.title'),
                    items : [
                        {value : "INS", display : this.$t('simple.type_demande.INS')},
                        {value : "EIT", display : this.$t('simple.type_demande.EIT')}, 
                    ],
                    data : [],
                    main : 'Transparence'
                },
                smr : {
                    title: this.$t('simple.smr.title'),
                    items : [
                        {value : "important", label : this.$t('simple.smr.important')},
                        {value : "modéré", label : this.$t('simple.smr.modere')},
                        {value : "faible", label : this.$t('simple.smr.faible')},
                        {value : "insuffisant", label : this.$t('simple.smr.insuffisant')}, 
                    ],
                    data : [],
                    main : 'Transparence'
                },
                asmr_obtenue : {
                    title: this.$t('simple.asmr'),
                    items : [
                        {value : "I", label : "I"},
                        {value : "II", label : "II"},
                        {value : "III", label : "III"},
                        {value : "IV", label : "IV"},
                        {value : "V", label : "V"}],
                    data : [],
                    main : 'Transparence'
                },
                ceesp : {
                    title: "CEESP",
                    items : [
                        {value : "avis", display :  this.$t('simple.Opinion on efficiency')},
                        {value : "publication", display : this.$t("simple.College publication")}, 
                    ],
                    data : [],
                    main : 'Ceesp'
                },
                name_prix : {     
                    title : this.$t('simple.name'), 
                    placeholder : "ex: Opdivo",  
                    data : [],
                    items: [],
                    input : "",
                    main : 'Prix'
                },
                prix : {
                    title: this.$t('simple.Price - Inscription'),
                    items : [
                        {value : "ss", label : "SS"},
                        {value : "coll", label : "Coll"},
                        {value : "t2a", label : "T2A"},
                        {value : "retro", label : this.$t("simple.Retrocession")},                        
                        {value : "atu_prix", label : this.$t("simple.ATU Price")},
                    ],
                    data : [],
                    check_all : false,
                    main : 'Prix'
                },
                cip : {                    
                    placeholder : "CIP7/CIP13",
                    data : '',
                    main : 'Prix'
                },
                cis : {                    
                    placeholder : "CIS",
                    data : '',
                    main : 'Prix'
                },
                ucd : {                    
                    placeholder : "UCD",
                    data : '',
                    main : 'Prix'
                },
                phase : {  
                    title : this.$t('essais_clinique.phase'),                  
                    placeholder : this.$t("Choose an item"),
                    items : [
                        {value : "Phase 1", label : "Phase 1"},
                        {value : "Phase 1/Phase 2", label : "Phase 1/Phase 2"},
                        {value : "Phase 2", label : "Phase 2"},
                        {value : "Phase 2/Phase 3", label : "Phase 2/Phase 3"},
                        {value : "Phase 3", label : "Phase 3"},
                        {value : "Phase 4", label : "Phase 4"},
                        {value : "N/A", label : "N/A"}],
                    data : [],
                    main : 'EssaisClinique'
                },
                primary_endpoint : {
                    title : this.$t('essais_clinique.primary_endpoint'),
                    placeholder : this.$t('Write here')+'...',
                    data : [],
                    items: [],
                    input : "",
                    main : 'EssaisClinique'
                },
                secondary_endpoint : {
                    title : this.$t('essais_clinique.secondary_endpoint'),
                    placeholder : this.$t('Write here')+'...',
                    data : [],
                    items: [],
                    input : "",
                    main : 'EssaisClinique'
                }
                
            }, 
            lang : localStorage.getItem('language'),
            disabledPrix : false,
            disabledDrug : false,
            disabledEssais : false,
            toggleBoxEssais : false,
            show_alert : true,
            limit: 20,
            loadingName: true,
            loadingTherapeutic: true
        }    
    },    
    updated(){ 
        if(this.datas.prix.data.length === this.datas.prix.items.length){
            this.datas.prix.check_all = true
        }else if(this.datas.prix.check_all){
            this.datas.prix.check_all = false
        }

        //check prix and other to disable input 
        if(this.datas.prix.data.length > 0 || this.datas.cip.data !== "" || this.datas.name_prix.data.length > 0 ){            
            this.disabledDrug = true
            this.disabledPrix = false
            this.disabledEssais = true
        }else if(this.datas.AAP.data.length > 0 || this.datas.mk_orphelin.data.length > 0  || this.datas.disease.data.length > 0 ||
         this.datas.date_start.data  || this.datas.date_end.data || this.datas.therapeutic.data.length > 0 ||
         this.datas.en_cours.data.length > 0 || this.datas.type_demande.data.length > 0 ||
         this.datas.smr.data.length > 0 || this.datas.asmr_obtenue.data.length > 0 || this.datas.ceesp.data.length > 0 || 
         this.datas.phase.data.length > 0 || this.datas.primary_endpoint.data.length > 0 || this.datas.secondary_endpoint.data.length > 0 ){            
            this.disabledPrix = true
            this.disabledDrug = false
            this.disabledEssais = false
        }
        // else if(this.datas.phase.data.length > 0 || this.datas.primary_endpoint.data.length > 0 || this.datas.secondary_endpoint.data.length > 0 ){
        //     this.disabledDrug = true
        //     this.disabledPrix = true
        //     this.disabledEssais = false
        // }
        else{
            this.disabledPrix = false            
            this.disabledDrug = false
            this.disabledEssais = false
        }

    },
    mounted(){ 
        this.loadAutocomplete()
    },     
    methods: {
        isAdmin,
        /**
         * Load data for autocomplete input in form
         */
        loadAutocomplete(){ 
            const keyLoadAutoComplete = []
            if(this.$store.getters['autocomplete/name'].length === 0){
                keyLoadAutoComplete.push('name')
            }
            if(this.$store.getters['autocomplete/therapeutic'].length === 0){
                keyLoadAutoComplete.push('therapeutic')
            }
            if(this.$store.getters['autocomplete/bp_name'].length === 0){
                keyLoadAutoComplete.push('bp_name')
            }
            if(this.$store.getters['autocomplete/measure_category'].length === 0){
                keyLoadAutoComplete.push('measure_category')
            }
            if(keyLoadAutoComplete.length > 0){
                this.$store.dispatch("autocomplete/loadAutocomplete", keyLoadAutoComplete)
            }

            if(this.autocomplete_name.length > 0 && this.loadingName)
                this.loadingName = false

            if(this.autocomplete_therapeutic.length > 0 && this.loadingTherapeutic)
                this.loadingTherapeutic = false     
        },      
         /**
         * Triggers when the therepeutic area modal is opened
         */
        onModal(param){
            const tempList = [];
            this.datas[param.key].data.forEach(item => {
                tempList.push(item)
            });
            
            for(let i in param.value){
                if(!tempList.includes(param.value[i])){                   
                    this.datas[param.key].data.push(param.value[i]);
                    if(param.key === 'therapeutic'){
                        this.datas[param.key].items.push(param.value[i]);
                        this.$refs.multiselectTherapeutic.select(param.value[i])
                    }
                }
            }
        },
        /**
         * Fire alert if CIP length != [7, 13] 
         */
        cipValue(){
            if(this.datas.cip.data.length > 0 && this.datas.cip.data.length !== 7 && this.datas.cip.data.length !== 13){
                alert("Veuillez saisir un CIP7 ou un CIP13.")
                this.datas.cip.data = ""
            }
        },

        /**
         * Triggers on simple form submit : parse, format and push data 
         */
        handleSubmit(){ 
            let strResultForm = "";
            //let public_keyword = false;
            for (const [key, items] of Object.entries(this.datas)) {
                if(items.main == "public"){
                    if(Array.isArray(items.data) && items.data.length > 0){                          
                        if(key === "therapeutic" || key === "name"){
                            const temp = [];
                            items.data.forEach(item => {
                                temp.push(item)
                            });
                            strResultForm += `${key}=${temp.join('#')}|` 
                        }else{
                            strResultForm += `${key}=${items.data.join('#')}|` 
                        }
                        //public_keyword = true;
                    }else if( (key === 'date_start' || key === "date_end") && items.data ){                        
                        strResultForm += `${key}=${moment(items.data).format("MM/YYYY")}|`
                        //public_keyword = true;
                    }else if(typeof items.data == 'string' && items.data !== ''){
                        strResultForm += `${key}=${items.data}|`
                        //public_keyword = true;
                    }
                    
                }else{
                    if(Array.isArray(items.data) && items.data.length > 0){
                        if(key === "name_prix" || key === "primary_endpoint" || key === "secondary_endpoint"){
                            const temp = [];
                            items.data.forEach(item => {
                                temp.push(item) 
                            });
                            strResultForm += `${items.main}@${key}=${temp.join('#')}|`
                        }else{
                            strResultForm += `${items.main}@${key}=${items.data.join('#')}|`
                        }
                         
                    }else if(typeof items.data == 'string' && items.data !== ''){
                        strResultForm += `${items.main}@${key}=${items.data}|`
                    }
                }
            }
            //console.log(strResultForm);
            if(strResultForm === ""){
                alert(this.$t('The field cannot be empty'));
                return false
            }

            let atu, transparence, ceesp, prix, essais_clinique; 
            strResultForm.indexOf("Acces_precoce@") > -1 ? atu = true : atu = false
            strResultForm.indexOf("Transparence@") > -1 ? transparence = true : transparence = false
            strResultForm.indexOf("Ceesp@") > -1 ? ceesp = true : ceesp = false
            strResultForm.indexOf("Prix@") > -1 ? prix = true : prix = false
            strResultForm.indexOf("EssaisClinique@") > -1 ? essais_clinique = true : essais_clinique = false
             

            if(prix){
                this.$router.push(`/advanced_result_prix/${base64url_encode(strResultForm)}`); // eslint-disable-line
            } 
            else{
                if(transparence && ceesp)
                    strResultForm += "main_agency=Transparence_Ceesp|";
                else if(transparence)
                    strResultForm += "main_agency=Transparence|";
                else if(ceesp)
                    strResultForm += "main_agency=Ceesp|";
                else if(atu)
                    strResultForm += "main_agency=AtuFrance|";


                let tab_essais_clinique = "";
                if(essais_clinique){
                    tab_essais_clinique = "?tab=essais_clinique";
                }
                this.$router.push(`/result/${base64url_encode(strResultForm)}/simple${tab_essais_clinique}`); // eslint-disable-line
            }
                
                
            

        },
        /**
         * Apply filter on autocomplete array on keypress
         */
        filteredName() {
            this.datas.name.items = this.autocomplete_name.filter(i => {
                return i.toLowerCase().indexOf(this.datas.name.input.toLowerCase()) !== -1 && i != "";
            });
        },
        filteredTherapeutic() {
            this.datas.therapeutic.items = this.autocomplete_therapeutic.filter(i => {
                return i.toLowerCase().indexOf(this.datas.therapeutic.input.toLowerCase()) !== -1;
            });
        },
        filteredBpName() {
            this.datas.name_prix.items = this.autocomplete_bp_name.filter(i => {
                return i.toLowerCase().indexOf(this.datas.name_prix.input.toLowerCase()) !== -1;
            });
        },
        filteredMeasureCategoryPrimary() {
            this.datas.primary_endpoint.items = this.autocomplete_measure_category.filter(i => {
                return i.toLowerCase().indexOf(this.datas.primary_endpoint.input.toLowerCase()) !== -1;
            });
        },
        filteredMeasureCategorySecondary() {
            this.datas.secondary_endpoint.items = this.autocomplete_measure_category.filter(i => {
                return i.toLowerCase().indexOf(this.datas.secondary_endpoint.input.toLowerCase()) !== -1;
            });
        },
        /**
         * Show options on multiselect input closing
         */
        optionsDisplay(value) {
            if(value.length > 1)
                this.datas.name.showOptions = true
        },
        /**
         * Hide options on multiselect input closing
         */
        onCloseDrugname() {
            this.datas.name.showOptions = false
        }

    },
    computed: {        
        user(){
            return this.$store.getters['auth/user']
        }, 
        autocomplete_name () {
            return this.$store.getters['autocomplete/name']
        },
        autocomplete_therapeutic () {  
            return this.$store.getters['autocomplete/therapeutic']
        },
        autocomplete_bp_name() {  
            return this.$store.getters['autocomplete/bp_name']
        },
        autocomplete_measure_category() {  
            return this.$store.getters['autocomplete/measure_category']
        },
        language(){
            return this.$i18n.locale !== 'en' ? this.languages[this.$i18n.locale] : this.languages[enGB]
        }
    },
    watch:{
        autocomplete_name(value) {
            if(value.length > 0)
                this.loadingName = false
        },
        autocomplete_therapeutic (value) {  
            if(value.length > 0)
                this.loadingTherapeutic = false
        },
    }
  
}
 
</script>
<style scoped>
.form-cs{
  background-color: #fff;
}
.input-search-plus{
    top:38px
}
</style>
